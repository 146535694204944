import React, { useEffect } from "react"
import { Layout } from "@components/layout"

import {
  processingPaymentAndOrder,
  PurchaseCompleted,
  PurchaseFailed,
} from "@components/ecommerce"
import {
  CartCtx,
  useActions,
  useContextState,
} from "@components/contexted"

// import './styles/summary.scss'

const Message = ({ location }) => {
  // const { payment } = useContextState(CartCtx, ["order", "payment"])
  const { payment } = useContextState(CartCtx, ["payment"])
  console.log('payment', payment)
  let url = location
  if (location.length > 0) {
    url = location.href.split("=")
  }

  let search = url.search.split("&")
  let orderId = search[0].split("=")[1]

  return (
    <React.Fragment>
      {payment.phase === "success" ? (
        <PurchaseCompleted orderId={orderId} />
      ) : (
        <PurchaseFailed orderId={orderId} msg={payment.msg} />
      )}
    </React.Fragment>
  )
}

const Summary = ({ pageContext, location }) => {
  const {
    changePaymentStatus,
    setOrderFromApi,
    resetCart,
  } = useActions(CartCtx, [
    "changePaymentStatus",
    "setOrderFromApi",
    "resetCart",
  ])
  useEffect(() => {
    processingPaymentAndOrder({
      location,
      changePaymentStatus,
      setOrderFromApi,
      resetCart,
    })
  }, [])
  const { page } = pageContext
  return (
    <Layout {...page}>
      <Message location={location} />
    </Layout>
  )
}
export default Summary
